/* eslint-disable */
export interface MoneyViewObject {
    cultureFormatted?: string;
    currency: string;
    invariantCultureFormatted?: string;
}
export interface MaintenanceViewModel {
    maintenanceIsActive: boolean;
}
export interface TypescriptViewModel {
    feature: Feature;
    siteName: SiteName;
}
export interface IErpResponseViewModel {
    isServiceAvailable: boolean;
}
export interface PaginatedViewModel {
    page: number;
    pageSize: number;
    totalHits: number;
}
export interface ProductGuideBrandsViewModel {
    brands: V2ProductGuideBrandViewObject[];
}
export interface ProductGuideDropdownViewModel {
    dropdownObjects: ProductGuideDropdownViewObject[];
    label: string;
}
export interface ProductGuideDropdownViewObject {
    id: string;
    isSelected: boolean;
    key?: string;
    name: string;
    parentId: string;
}
export interface ProductGuideSearchResultViewModel {
    hasMoreResults: boolean;
    results: ProductGuideSearchResultViewObject[];
}
export interface ProductGuideSearchResultViewObject {
    name: string;
    productGuideEntryId: string;
    productGuideEntryKey?: string;
}
export interface ProductGuideSerialSearchGuideViewObject {
    guideEntryId: string;
    label: string;
    level: number;
    name: string;
}
export interface ProductGuideSerialSearchResultItemViewObject {
    itemCategory?: string;
    itemDescription?: string;
    itemImageUrls: string[];
    itemModelRelation: string;
    manufacturerPartNumber?: string;
    wid?: string;
}
export interface ProductGuideSerialSearchResultViewModel {
    guideEntries: ProductGuideSerialSearchGuideViewObject[];
    items: ProductGuideSerialSearchResultItemViewObject[];
    result?: ProductGuideSerialSearchResultViewObject;
}
export interface ProductGuideSerialSearchResultViewObject {
    modelDescription?: string;
    modelImageUrl?: string;
    modelNumber?: string;
    modelType?: string;
}
export interface ProductGuideViewModel {
    brand: V2ProductGuideBrandViewObject;
    dropdowns: ProductGuideDropdownViewModel[];
    maxDropdownCount: number;
    searchHasResults: boolean;
}
export interface AssetViewObject {
    extension: string;
    isOriginIceCat: boolean;
    name?: string;
    sortOrder: number | undefined;
    uri: string;
}
export interface BrandLogoViewModel {
    brandLogoUrl: string;
    brandLogoUrl125px: string;
    brandLogoUrl250px: string;
}
export interface DocumentViewObject extends AssetViewObject {
    assetType: AssetType;
    description?: string;
    languages: string[];
    thumbnailUrl?: string;
    uri: string;
}
export interface ImageViewObject extends AssetViewObject {
    uri: string;
}
export interface ProductSheetAssetViewObject extends DocumentViewObject {
    uri: string;
}
export interface V2ProductGuideBrandViewObject {
    brandId: string;
    brandLogoUrl: string;
    brandLogoUrl125px: string;
    brandLogoUrl250px: string;
    brandName: string;
    brandNo: number;
    productGuideRootId: string;
}
export interface VideoViewObject extends AssetViewObject {
    uri: string;
}
export interface CreateProductGuideOwnershipViewModel {
    externalId: string;
}
export interface ProductGuideBrandViewModel {
    brands: ProductGuideBrandViewObject[];
    isServiceAvailable: boolean;
}
export interface ProductGuideBrandViewObject {
    brandId: number;
    brandName: string;
}
export interface ProductGuideConfigurationViewModel {
    allowedBrands: string[];
    externalProductLink?: string;
    externalProductLinkText?: string;
    externalStylesheet?: string;
    hideStock: boolean;
    invertTheme: boolean;
    language: string;
    showFacets: boolean;
    startPage: string;
    theme?: string;
    type: string;
}
export interface ProductGuideItemViewModel extends SearchViewModel {
    categories: MenuItem[];
    facets?: FacetsViewModel;
}
export interface ProductGuideItemViewObject {
    manufacturerPartNo?: string;
    product: ProductTileViewObject;
    wid: string;
}
export interface ProductGuideModelViewModel {
    isServiceAvailable: boolean;
    models: ProductGuideModelViewObject[];
}
export interface ProductGuideModelViewObject {
    modelId: string;
    modelName: string;
}
export interface ProductGuideOwnershipViewModel {
    guides: ProductGuideOwnershipViewObject[];
}
export interface ProductGuideOwnershipViewObject {
    configuration: ProductGuideConfigurationViewModel;
    created: string;
    email: string;
    externalId: string;
    fullName: string;
    name: string;
    websiteUrl: string;
}
export interface ProductGuideSearchGroupViewObject {
    displayName: string;
    models: ProductGuideSearchViewObject[];
}
export interface ProductGuideSearchViewModel {
    isServiceAvailable: boolean;
    models: ProductGuideSearchGroupViewObject[];
}
export interface ProductGuideSearchViewObject {
    brandId: number;
    brandName: string;
    modelId: string;
    modelName: string;
    seriesName: string;
}
export interface ProductGuideSeriesViewModel {
    isServiceAvailable: boolean;
    series: ProductGuideSeriesViewObject[];
}
export interface ProductGuideSeriesViewObject {
    seriesName: string;
}
export enum SelectionTypeViewObject {
    MultiSelect = 1,
    Toggle = 2,
    SingleSelect = 3
}
export interface FacetsViewModel {
    facets: FacetViewObject[];
    filters?: FacetViewObject;
    sorting?: FacetViewObject;
}
export interface FacetValueViewObject {
    assetUrl?: string;
    displayLabel: string;
    displayName: string;
    isFacet: boolean;
    key: string;
    keys: string[];
    productCount: number;
    selected: boolean;
}
export interface FacetViewObject {
    enabled: boolean;
    facetDisplayLabel?: string;
    facetDisplayName: string;
    facetId: string;
    highlight: boolean;
    isFacet: boolean;
    isRangeCompatible: boolean;
    position: number;
    rangeFrom: number | undefined;
    rangeTo: number | undefined;
    selectionType: SelectionTypeViewObject;
    unitClarification?: string;
    values: FacetValueViewObject[];
}
export interface ProductFlatViewObject {
    brand: string;
    categoryId?: string;
    description?: string;
    displayName?: string;
    image?: ImageViewObject;
    inStock: number;
    itemId: string;
    mpn?: string;
    name?: string;
    url: string;
    wid: string;
}
export interface ProductIdsViewModel {
    products: ProductIdsViewObject[];
}
export interface ProductIdsViewObject {
    id: string;
    mpn?: string;
}
export interface ProductTileViewModel {
    products: ProductTileViewObject[];
}
export interface ProductTileViewObject {
    accessories: ProductRelationViewObject[];
    alternatives: ProductRelationViewObject[];
    bid?: BidViewObject;
    blocking: number | undefined;
    brand: string;
    campaigns: CampaignViewObject[];
    categoryId?: string;
    conditionCode: ProductConditionCode | undefined;
    description?: string;
    designedFor?: string;
    displayName?: string;
    gppg: string;
    hasAccessories: boolean;
    hasExactAlternatives: boolean;
    hasSimilarAlternatives: boolean;
    image?: ImageViewObject;
    isNew: boolean;
    itemId: string;
    mpn?: string;
    name?: string;
    productId: string;
    productTypeCode?: string;
    salesIndex: number | undefined;
    score: number | undefined;
    subLine?: string;
    url: string;
    wid: string;
}
export interface SearchFallbackExplanationViewmodel {
    interpolationValues?: string[];
    label: string;
}
export interface SearchProductFlatViewModel {
    dubletsFound: boolean;
    product: ProductFlatViewObject;
}
export interface SearchTranslationViewObject {
    hits: number;
    translation: string;
}
export interface SearchViewModel {
    brandName?: string;
    explanation?: string;
    hasItemSelectionCode: boolean;
    hitsShowing: number;
    lastPage: number;
    page: number;
    productGuideBrands: V2ProductGuideBrandViewObject[];
    products: ProductTileViewObject[];
    sanitizedTerm?: string;
    searchFallbackExplanation?: SearchFallbackExplanationViewmodel;
    showPriceSort: boolean;
    totalHits: number;
}
export interface SuggestionViewObject {
    suggestion: string;
}
export interface BidsViewModel {
    bids: BidViewObject[];
    facets: FacetViewObject[];
}
export interface BidViewObject {
    bidNo: string;
    brand?: string;
    customerId: string;
    description?: string;
    displayName: string;
    endDate?: string;
    id: number;
    image?: ImageViewObject;
    itemId: string;
    minOrderQty: number;
    mpn?: string;
    name: string;
    price?: MoneyViewObject;
    quantity: number;
    remQty: number;
    shortProductDescription?: string;
    url: string;
    usedQty: number;
    webCategory?: string;
    wid: string;
}
export interface DiscountViewObject {
    amount?: MoneyViewObject;
    quantity: number;
}
export interface DutyViewObject {
    dutyCode: string;
    dutyDescription: string;
    price: MoneyViewObject;
    vatAmount: MoneyViewObject;
    vatPercent: string;
}
export interface PriceDetailedViewModel {
    isServiceAvailable: boolean;
    prices: PriceDetailedViewObject[];
}
export interface PriceDetailedViewObject {
    currencyCode?: string;
    discounts?: DiscountViewObject[];
    duties?: DutyViewObject[];
    itemId: string;
    previousPrice: MoneyViewObject;
    price: MoneyViewObject;
    suggestedRetailPrice: MoneyViewObject;
    vatAmount: MoneyViewObject;
    vatPercent: string;
}
export interface PriceSimpleViewModel {
    isServiceAvailable: boolean;
    prices: PriceSimpleViewObject[];
}
export interface PriceSimpleViewObject {
    amount: MoneyViewObject;
    hasQuantityDiscounts: boolean;
    itemId: string;
    previousPrice: MoneyViewObject;
    suggestedRetailPrice: MoneyViewObject;
}
export interface CampaignViewObject {
    campaignCode: string;
    campaignIconUrl?: string;
    description: string;
    showCampaignIcon: boolean;
}
export interface CategoryFallbackViewObject extends ImageViewObject {
    isDefaultFallback: boolean;
}
export interface CategoryTreeViewModel {
    breadcrumb: MenuItem[];
    menuItems: MenuItem[];
    rootMenu?: MenuItem;
    selectedMenu?: MenuItem;
}
export interface CompabilityViewObject {
    modelDisplayName: string;
    systemDisplayName: string;
}
export interface FrequentlyAskedQuestionViewObject {
    answer: string;
    question: string;
}
export interface ProductAttributeViewObject {
    displayName: string;
    label: string;
    unit?: string;
    url?: string;
    value?: string;
    values?: string[];
}
export interface ProductBidsViewModel {
    bids: ProductBidViewObject[];
}
export interface ProductBidViewObject {
    productBids: ProductTileViewObject[];
    productWid: string;
}
export interface ProductDetailsViewModel {
    accessories: ProductRelationViewObject[];
    alternatives: ProductRelationViewObject[];
    alternativeUrls: AlternativeUrlViewObject[];
    bids: ProductTileViewObject[];
    blocking: number | undefined;
    brand: string;
    brandLogos?: BrandLogoViewModel;
    brandUrl: string;
    breadCrumb: LinkItem[];
    bulletPoints?: string[];
    campaigns: CampaignViewObject[];
    canonical: string;
    categoryFallbackImage?: ImageViewObject;
    categoryFallbackImageMap: KeyValuePair<string, CategoryFallbackViewObject>[];
    categoryId?: string;
    conditionCode: ProductConditionCode | undefined;
    designedFor?: string;
    displayName: string;
    documents: DocumentViewObject[];
    ean?: string;
    frequentlyAskedQuestions: FrequentlyAskedQuestionViewObject[];
    gppg: string;
    hasAccessories: boolean;
    hasExactAlternatives: boolean;
    hasProductSheet: boolean;
    hasSimilarAlternatives: boolean;
    images: ImageViewObject[];
    isNew: boolean;
    isRackConfigurable: boolean;
    itemId: string;
    longDescription?: string;
    mpn?: string;
    oem?: string;
    pph?: string;
    productGroup?: string;
    productGuideBrands: V2ProductGuideBrandViewObject[];
    productSeries?: string;
    reasonsToBuy: ReasonToBuyViewObject[];
    shortDescription?: string;
    specifications: SpecificationViewObject[];
    subLine?: string;
    videoes: VideoViewObject[];
    wid: string;
}
export interface ProductRelationViewObject {
    productId: string;
    relationType: string;
}
export interface ProductSeriesDropDownValueViewObject {
    displayValue: string;
    wid: string;
}
export interface ProductSeriesDropDownViewObject {
    displayName: string;
    facetId: string;
    values: ProductSeriesDropDownValueViewObject[];
}
export interface ProductSeriesProductFacetViewObject {
    displayValue: string;
    facetId: string;
    rawValue: any;
}
export interface ProductSeriesProductViewObject {
    displayName: string;
    facetValues: KeyValuePair<string, ProductSeriesProductFacetViewObject>[];
    imageUrl?: string;
    isActive: boolean;
    isLimited: boolean;
    isSelected: boolean;
    itemId: string;
    mpn: string;
    productUrl: string;
    wid: string;
}
export interface ProductSeriesViewObject {
    dropDowns: ProductSeriesDropDownViewObject[];
    productSeriesProducts: ProductSeriesProductViewObject[];
}
export interface ReasonToBuyViewObject {
    bodyText: string;
    imageUrl?: string;
    sortOrder: number | undefined;
    title: string;
}
export interface ReferencedProductsViewModel {
    categoryFacet: FacetViewObject;
    from: number;
    hasMorePages: boolean;
    products: ProductTileViewObject[];
    size: number;
    totalProducts: number;
}
export interface RelatedModelsGroupViewObject {
    brandId: number;
    brandName: string;
    relatedModels: RelatedModelsViewObject[];
}
export interface RelatedModelsViewModel {
    isServiceAvailable: boolean;
    relatedModelGroups: RelatedModelsGroupViewObject[];
}
export interface RelatedModelsViewObject {
    brandSeries: string;
    overflow: boolean;
    relations: RelationViewObject[];
}
export interface RelationViewObject {
    fullModelName: string;
    modelId: string;
    modelName: string;
}
export interface SpecificationViewObject {
    displayName: string;
    features: ProductAttributeViewObject[];
    label: string;
}
export interface LinkItem {
    id: string;
    name: string;
    url: string;
}
export interface MenuItem {
    categoryIds: string[];
    children: MenuItem[];
    disabled: boolean;
    displayLabel?: string;
    displaySequence: number;
    highlight: boolean;
    id: string;
    name: string;
    parentId: string;
    productCount: number;
    selected: boolean;
    url: string;
}
export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}
export interface ProductGuideConfiguration {
    allowedBrands: string[];
    externalProductLink?: string;
    externalProductLinkText?: string;
    externalStylesheet?: string;
    hideStock: boolean;
    invertTheme: boolean;
    language: string;
    showFacets: boolean;
    startPage: string;
    theme: string;
    type: string;
}
export interface ProductGuideSearchRequest extends SearchRequest {
    guideId: string;
    level1CategoryId?: string;
    modelId: string;
}
export interface SaveExternalGuideRequest {
    configuration: ProductGuideConfiguration;
    email: string;
    externalId: string | undefined;
    fullName: string;
    guideName: string;
    websiteUrl: string;
}
export interface ProductExportRequest {
    exportFormat: ExportFormat;
    searchUri: string;
}
export interface SearchRequest {
    allowedBrands?: string[];
    brandName?: string;
    cableEndTypeA?: string;
    cableEndTypeB?: string;
    cableType?: string;
    campaignCode?: string;
    categoryId?: string;
    categoryIds?: string[];
    explain: boolean | undefined;
    filter: SearchFilters | undefined;
    isExternalProductGuide?: boolean | undefined;
    lastFacet?: string;
    page: number | undefined;
    pageSize: number | undefined;
    priceSort: SearchPriceSort | undefined;
    productGuideId?: string;
    searchWithinResult?: string;
    showInStockFilter?: boolean | undefined;
    showScore: boolean | undefined;
    term?: string;
    useFlattenedCategoryTree: boolean | undefined;
    wIds: string[];
}
export enum SearchFilters {
    None = 0,
    InStock = 1,
    New = 2,
    Refurbished = 4,
    CampaignClearance = 8,
    CampaignsExceptClearance = 16,
    All = 31
}
export enum SearchPriceSort {
    None = 0,
    Ascending = 1,
    Descending = 2
}
export interface SelectedFacet {
    key: string;
    negate: boolean;
    position: number;
    rangeFrom: number | undefined;
    rangeTo: number | undefined;
    values: string[];
}
export enum AllUsersColumn {
    UserGroup = 0,
    Name = 1,
    Email = 2,
    JobTitle = 3,
    LastLogin = 4
}
export interface AllUsersRequest {
    page?: number;
    pageSize?: number;
    searchText?: string;
    sortColumn?: AllUsersColumn;
    sortOrder?: ListSortDirection;
}
export interface SalesRepViewModel {
    isServiceAvailable: boolean;
    salesRep?: SalesRepViewObject;
}
export interface SalesRepViewObject {
    directPhoneNo?: string;
    email?: string;
    initials: string;
    mobilePhoneNo?: string;
    name?: string;
    phoneNo?: string;
    picture?: string;
    title?: string;
}
export interface UsersViewModel extends PaginatedViewModel {
    inactiveUsers: number;
    users: UserViewObject[];
}
export interface UserViewModel {
    userViewObject: UserViewObject;
}
export interface UserViewObject {
    companyName?: string;
    customerNo: string;
    departmentCode?: string;
    directPhoneNo?: string;
    email: string;
    id: number;
    includeInCampaigns: boolean;
    isInactive: boolean;
    jobTitle?: string;
    lastLogin?: string;
    lastLoginLabel?: string;
    marketName: string;
    mobilePhoneNo?: string;
    name: string;
    segment?: string;
    userGroup: string;
    userId: string;
}
export interface CrudUserRequest {
    directPhoneNo?: string;
    email: string;
    includeInCampaigns: boolean;
    jobTitle?: string;
    mobilePhoneNo?: string;
    name: string;
    userGroup: string;
    userId: string;
}
export interface RequestServiceRequest {
    details: string;
    integrationType: string;
    type: RequestServiceType;
}
export interface TranslationResultViewModel {
    translatedBulletPoints: string[];
    translatedDescription?: string;
}
export enum DurationTypeViewObject {
    Day = 0,
    Days = 1,
    Week = 2,
    Weeks = 3,
    Month = 4,
    Months = 5
}
export interface StockInformationViewModel {
    isServiceAvailable: boolean;
    stockByItemId: StockViewObject[];
}
export interface StockInformationViewObject {
    hasStock: boolean;
    locationType: StockLocationTypeViewObject;
    stockInformationText: string;
}
export interface StockViewObject {
    itemId: string;
    simpleStockInformation: StockInformationViewObject[];
    stockInformation: StockInformationViewObject[];
}
export interface SwitchSiteRequest {
    siteId: number;
}
export interface CountryOptionsViewModel {
    countries: CountryOptionViewObject[];
}
export interface CountryOptionViewObject {
    countryCode: string;
    id: number;
    sites: SiteViewObject[];
}
export interface CountryPickerOptionsViewModel {
    options: CountryPickerOptionViewObject[];
}
export interface CountryPickerOptionViewObject {
    countryCode: string;
}
export interface DefaultSiteViewModel {
    countryCode: string;
    culture: string;
    erpBusinessEntityId: number;
    languageCode: string;
    siteId: number;
    url: string;
}
export interface SiteViewObject {
    countryCode: string;
    languageCode: string;
    siteId: number;
    url: string;
}
export interface SwitchSiteResponseViewModel {
    countryCode: string;
    culture: string;
    erpBusinessEntityId: number;
    languageCode: string;
    marketId: number;
    siteId: number;
    token?: string;
    url: string;
}
export interface AddressRequestObject {
    city: string;
    code?: string;
    companyName: string;
    contact?: string;
    countryCode: string;
    email: string;
    isDefault: boolean;
    isEditable: boolean;
    phone?: string;
    postalCode?: string;
    region?: string;
    street: string;
    street2?: string;
    webId?: string;
}
export interface AddressViewObject {
    city: string;
    code?: string;
    companyName: string;
    contact?: string;
    countryCode: string;
    email?: string;
    phone?: string;
    postalCode?: string;
    region?: string;
    street: string;
    street2?: string;
}
export interface ManufacturerRmaContactInfoViewObject {
    brand: BrandLogoViewObject;
    comment: string;
    email: string;
    phone: string;
    rejectionType: number | undefined;
    webLink: string;
}
export interface RmaBasketLineItemViewObject {
    description: string;
    displayName: string;
    image?: ImageViewObject;
    imageUrls: string[];
    invoiceNo: string;
    itemId: string;
    lineItemId: number;
    mpn: string;
    orderNo: string;
    originalPackage: boolean;
    quantity: number;
    reasonId: number;
    receivedWithReservations: boolean;
    referenceNo: string;
    serialNumbers: string[];
    shipmentNo: string;
}
export interface RmaBasketViewModel {
    rmaBasket: RmaBasketViewObject;
}
export interface RmaBasketViewObject {
    address?: AddressViewObject;
    invoiceAddress?: AddressViewObject;
    lineItems: RmaBasketLineItemViewObject[];
    reasonType: RmaReasonType;
    userId: number;
}
export interface RmaHeaderTrackingLinkObject {
    itemId?: string;
    shippedQty: number;
    shippingAgent?: string;
    trackingLink?: string;
    trackingNo?: string;
}
export interface RmaHeaderViewModel {
    rmaHeaders: RmaHeaderViewObject[];
}
export interface RmaHeaderViewObject {
    address: AddressViewObject;
    creditMemo?: string;
    creditNumber: string;
    date: string;
    dispatchInfo?: string;
    documentNo?: string;
    itemId: string;
    itemUrl?: string;
    manufacturerPartNo?: string;
    mpnNumber?: string;
    orderStatus: RmaHeaderOrderStatus;
    quantity: number;
    receivedQty: number;
    referenceNo?: string;
    referenceNoLine?: string;
    shippingInfo?: RmaShippingInfoViewObject;
    trackingLink?: RmaHeaderTrackingLinkObject;
    type: RmaType;
}
export interface RmaLineItemViewObject {
    displayName: string;
    image?: ImageViewObject;
    itemId?: string;
    manufacturerContactInfo?: ManufacturerRmaContactInfoViewObject;
    mpn?: string;
    quantity: number;
    serialNumbers: string[];
}
export interface RmaReasonViewObject {
    descriptionHelpLabel: string;
    imageHelpLabel: string;
    originalPackage: boolean;
    reasonId: number;
    reasonLabel: string;
    receivedWithReservations: boolean;
    subReasons: RmaSubReasonViewObject[];
    uploadImage: ImageRequirement;
}
export interface RmaSearchResultsViewModel {
    rmaSearchResults: RmaSearchResultViewObject[];
}
export interface RmaSearchResultViewObject {
    date: string;
    invoiceNumber: string;
    lineItem: RmaLineItemViewObject[];
    orderNumber: string;
    referenceNumber: string;
    shipmentNumber: string;
}
export interface RmaShippingInfoViewObject {
    shipToAddress: string;
    shipToCity: string;
    shipToContact: string;
    shipToCountryCode: string;
    shipToName: string;
    shipToPostCode: string;
}
export interface RmaStatusViewModel {
    rmaStatuses: RmaStatusViewObject[];
}
export interface RmaStatusViewObject {
    hasActiveRma: boolean;
    reasonType: RmaReasonType;
}
export interface RmaSubReasonViewObject {
    descriptionHelpLabel: string;
    imageHelpLabel: string;
    originalPackage: boolean;
    reasonId: number;
    reasonLabel: string;
    receivedWithReservations: boolean;
    uploadImage: ImageRequirement;
}
export interface RmaUploadResultViewModel {
    images: string[];
}
export interface RmaViewModel {
    rmaReasons: RmaReasonViewObject[];
}
export interface BrandGroupViewObject {
    brands: BrandLogoViewObject[];
    name: string;
}
export interface BrandLogoViewObject {
    brandName: string;
    logoUrl: string;
    url: string;
}
export interface BrandViewModel {
    brandGroup: BrandGroupViewObject[];
}
export interface AddOrUpdateRequestLineItem {
    description: string;
    imageUrls: string[];
    invoiceNo: string;
    itemId: string;
    lineItemId: number | undefined | null;
    mpn: string;
    orderNo: string;
    originalPackage: boolean;
    quantity: number;
    reasonId: number;
    receivedWithReservations: boolean;
    referenceNo: string;
    serialNumbers: string[];
    shipmentNo: string;
}
export interface AddOrUpdateRmaBasketRequest {
    lineItems: AddOrUpdateRequestLineItem[];
    reasonType: RmaReasonType;
}
export interface CreateRmaRequest {
    reasonType: RmaReasonType;
}
export interface UpdateRmaBasketAddressRequest {
    deliveryAddress: AddressRequestObject;
    reasonType: RmaReasonType;
}
export enum PopularityType {
    Purchased = 1,
    Viewed = 2
}
export enum ProductRecommendationType {
    RecentlyViewedProducts = 1,
    PurchasedWithBasket = 2,
    PopularProducts = 3,
    PurchasedWithProduct = 4,
    ViewedAfterViewingProduct = 5
}
export interface RecommendationsBrandsRequest {
    brandCount: number;
    brandIdFilterList?: string[];
    categoryIdFilterNegativeList?: string[];
    categoryIdFilterPositiveList?: string[];
    displayedAtLocation: string;
    periodDays: number;
    popularityType: PopularityType;
    useDiverseRecommendations: boolean;
}
export interface RecommendationsBySearchTermRequest {
    allowFillIfNecessary: boolean;
    brandId?: string;
    brandIdFilterList?: string[];
    categoryId?: string;
    categoryIdFilterNegativeList?: string[];
    categoryIdFilterPositiveList?: string[];
    displayedAtLocation: string;
    hasImages: boolean;
    productCount: number;
    searchTerm: string;
    showFeedProducts: boolean;
    stockFilter: ProductRecommendationStockFilterType;
}
export interface RecommendationsCategoriesRequest {
    brandIdFilterList?: string[];
    categoryCount: number;
    categoryIdFilterNegativeList?: string[];
    categoryIdFilterPositiveList?: string[];
    displayedAtLocation: string;
    periodDays: number;
    popularityType: PopularityType;
}
export interface RecommendationsRequest {
    allowFillIfNecessary: boolean;
    brandId?: string;
    brandIdFilterList?: string[];
    categoryId?: string;
    categoryIdFilterNegativeList?: string[];
    categoryIdFilterPositiveList?: string[];
    displayedAtLocation: string;
    hasImages: boolean;
    intervalDays: number;
    popularityType: PopularityTypes;
    productCount: number;
    productId?: string;
    recommendationType: ProductRecommendationType;
    showFeedProducts: boolean;
    stockFilter: ProductRecommendationStockFilterType;
}
export interface RecommendedBrandsViewModel {
    recommendedBrands: RecommendedBrandViewObject[];
}
export interface RecommendedBrandViewObject {
    brandLogo: ImageViewObject;
    brandName: string;
    categoryCount: number;
    productCount: number;
}
export interface RecommendedCategoriesViewModel {
    recommendedCategories: RecommendedCategoryViewObject[];
}
export interface RecommendedCategoryViewObject {
    brandCount: number;
    categoryId: string;
    displayName: string;
    images: ImageViewObject[];
    productCount: number;
    url: string;
}
export interface AddOrUpdatePlaceholderItemToRackConfigurationRequest {
    capacitySize: number;
    configurationId: string | undefined;
    desiredPosition: number | undefined;
    displayName: string;
    placeholderItemId: string | undefined;
    rackItemId: string;
}
export interface DownloadAssemblyGuideRequest {
    configurationId: string;
}
export interface DuplicateRackViewModel {
    duplicatedRackConfigurationId: string;
}
export interface RackAccessoriesSearchViewModel {
    groups: RackAccessoryGroupViewObject[];
}
export interface RackAccessoryGroupViewObject {
    accessories: RackAccessoryViewObject[];
    displayName: string;
}
export interface RackAccessoryViewObject {
    displayName: string;
    gppg: string;
    hasAccessories: boolean;
    image: ImageViewObject;
    itemId: string;
    mpn: string;
    productUrl: string;
    type: string;
    units: number;
    wid: string;
}
export interface RackConfigurationsViewModel {
    configurations: RackConfigurationViewObject[];
}
export interface RackConfigurationViewModel {
    accessoriesTotal?: MoneyViewObject;
    capacity: number;
    componentsTotal?: MoneyViewObject;
    depth: number;
    id: string;
    name: string;
    placeholderItems: RackPlaceholderItemViewObject[];
    primaryItems: RackPrimaryItemViewObject[];
    rack: RackViewModelObject;
    rackPrice?: MoneyViewObject;
    secondaryItems: RackSecondaryItemViewObject[];
    total?: MoneyViewObject;
    userId: number | undefined;
    wid: string;
}
export interface RackConfigurationViewObject {
    componentCount: number;
    productImage?: string;
    productName: string;
    productWid: string;
    rackId: string;
    rackName: string;
}
export interface RackFacetValueViewObject {
    displayName: string;
    isSelected: boolean;
    productCount: number;
    value: string;
}
export interface RackFacetViewObject {
    displayName: string;
    facetId: string;
    position: number;
    values: RackFacetValueViewObject[];
}
export interface RackPlaceholderItemViewObject {
    capacitySize: number;
    displayName: string;
    id: string;
    position: number;
}
export interface RackPrimaryItemViewObject {
    capacitySize: number;
    displayName: string;
    gppg: string;
    hasAccessories: boolean;
    id: string;
    image?: ImageViewObject;
    itemId: string;
    mpnNumber: string;
    position: number;
    productUrl: string;
    wid: string;
}
export interface RackSearchViewModel {
    rackFacets: RackFacetViewObject[];
    racks: StepTwoRackTileViewObject[];
}
export interface RackSecondaryItemViewObject {
    displayName: string;
    gppg: string;
    id: string;
    image?: ImageViewObject;
    itemId: string;
    mpnNumber: string;
    productUrl: string;
    quantity: number;
    wid: string;
}
export interface RackViewModelObject {
    brandLogos: BrandLogoViewModel;
    brandName: string;
    brandUrl: string;
    displayName: string;
    gppg: string;
    images: ImageViewObject[];
    itemId: string;
    mpnNumber: string;
    preassembled: boolean;
    productUrl: string;
    videos: VideoViewObject[];
    wid: string;
}
export interface StepTwoRackTileViewObject {
    brand: string;
    categoryId?: string;
    color: string;
    depth: string;
    displayName: string;
    gppg: string;
    image: ImageViewObject;
    itemId: string;
    mpn: string;
    units: number;
    wid: string;
    width: string;
}
export interface AddPrimaryItemToConfigurationRequest {
    configurationId: string | undefined;
    desiredPosition: number | undefined;
    itemId: string;
    rackItemId: string;
}
export interface AddSecondaryItemToConfigurationRequest {
    configurationId: string;
    id: string | undefined;
    itemId: string;
    quantity: number;
}
export interface CreateRackConfigurationRequest {
    rackItemId: string;
}
export interface DeleteRackConfigurationRequest {
    configurationId: string;
}
export interface DownloadPriceListRequest {
    configurationId: string;
}
export interface DuplicateRackConfigurationRequest {
    configurationId: string;
}
export interface MoveItemInConfigurationRequest {
    configurationId: string;
    desiredPosition: number;
    itemId: string;
}
export interface RackSearchRequest {
    lastFacet?: string;
    rackType: RackType;
    selectedFacets: SelectedRackFacetsRequest[];
}
export interface RemoveItemFromConfigurationRequest {
    configurationId: string;
    itemId: string;
}
export interface SelectedRackFacetsRequest {
    facetId: string;
    values: string[];
}
export interface UpdateRackConfigurationNameRequest {
    configurationId: string;
    name: string;
}
export interface OciLoginRequest {
    hookUrl: string;
    password: string;
    username: string;
}
export interface KeyValuePair<TKey, TValue> {
    key: TKey;
    value: TValue;
}
export interface AlternativeUrlViewModel {
    alternativeUrls: AlternativeUrlViewObject[];
}
export interface AlternativeUrlViewObject {
    language: string;
    url: string;
}
export interface ProductIssueReportRequest {
    brandName: string;
    description: string;
    issueCategory: string;
    itemId: string;
    mpn: string;
    productName: string;
    productUrl: string;
    wid: string;
}
export interface ReferencedProductsRequest {
    categoryIds: string[];
    from: number;
    productWid: string;
    size: number;
}
export interface DownloadProductSheetRequest {
    culture: string;
    erpBusinessEntityId: number;
    wid: string;
}
export interface AdContentBlocksApiRequest extends BasePersonalizedContentApiRequest {
    adUnitIds: string[];
}
export interface BasePersonalizedContentApiRequest {
    brands?: string[];
    contactKey: string;
    currentUrl: string;
    customerKey?: string;
    searchTerm?: string;
}
export interface BasketPageApiRequest extends BasePersonalizedContentApiRequest {
    categoryIds: string[];
    itemIds: string[];
}
export interface BrandLogosApiRequest extends BasePersonalizedContentApiRequest {
    breakpoint: RequestBreakpoint;
}
export interface PartnerLogosApiRequest extends BasePersonalizedContentApiRequest {
    brandName: string;
    condition?: string;
    department?: string;
    itemNo: string;
    mpnNumber: string;
    pph?: string;
    productCategory: string;
    productGroup?: string;
}
export interface ProductDetailsPageAdRequest extends BasePersonalizedContentApiRequest {
    brand: string;
    categoryIds: string[];
    itemId: string;
}
export interface ProductListApiRequest extends BasePersonalizedContentApiRequest {
    brandName?: string;
    categoryIds: string[];
    itemIds: string[];
    listLocation: string;
}
export interface RecommendedBrandsApiRequest extends BasePersonalizedContentApiRequest {
}
export interface NotificationListViewModel {
    itemIds: string[];
}
export interface CategoryNamesViewModel {
    names: { [key: string]: string };
}
export interface CategoryNameViewModel {
    name?: string;
}
export interface MegaMenuViewModel {
    menu: MenuItem[];
}
export interface IntegrationQueueStatusViewModel {
    dateOfOldestFileInQueue: Date;
    totalFilesInQueue: number;
}
export interface MailQueueStatusViewModel {
    errorCountsByStatusCode: KeyValuePair<string, number>[];
    errorMailsInQueue: number;
}
export interface MaintenanceViewModel {
    maintenance: boolean;
}
export interface BeginImpersonationRequest {
    userId: number;
}
export interface ImpersonationViewModel {
    siteUrl?: string;
    token: string;
}
export interface SearchImpersonationViewModel {
    hasMoreResults: boolean;
    users: UserViewObject[];
}
export interface BotIpVersionShouldUpdateViewModel {
    newVersion: Date | undefined;
    shouldUpdate: boolean;
}
export interface CommitBotVersionRequest {
    apiKey: string;
    clientId: string;
    version: Date;
}
export interface AddOrUpdateFavoriteItemListRequest {
    items: FavoriteListItemRequestObject[];
    listId: number;
}
export interface CreateFavoriteListRequest {
    description?: string;
    name: string;
    shareWithOrganization?: CustomerShareOptions[];
    shareWithUsers: UserShareOptions[];
}
export interface FavoriteListItemRequestObject {
    itemId: string;
    quantity: number;
}
export interface RemoveItemsFromFavoriteListRequest {
    itemIds: string[];
    listId: number;
}
export interface UpdateFavoriteListRequest extends CreateFavoriteListRequest {
    id: number;
}
export interface CustomerShareOptions extends ShareOptions {
    customerId: number;
}
export interface ShareOptions {
    permission: FavoriteListPermission;
}
export interface UserShareOptions extends ShareOptions {
    userId: number;
}
export interface FavoriteListDetailsViewModel {
    details: FavoriteViewObject;
}
export interface FavoriteListViewModel {
    myShared: FavoriteSharedListViewObject[];
    private: FavoriteListViewObject[];
    sharedWithMe: FavoriteSharedListViewObject[];
}
export interface FavoriteListViewObject {
    createdBy: number;
    description?: string;
    id: number;
    itemsCount: number;
    name: string;
    permission: FavoriteListPermission;
}
export interface FavoriteSharedListViewObject extends FavoriteListViewObject {
    sharedWithOrganizationCount: number;
    sharedWithUsersCount: number;
}
export interface FavoritesItemsListedViewModel {
    listed: FavoritesItemsListedViewObject[];
}
export interface FavoritesItemsListedViewObject {
    itemId: string;
    listIds: number[];
}
export interface FavoriteViewObject {
    description?: string;
    id: number;
    listOwnerId: number;
    name: string;
    products: ProductTileViewObject[];
    sharedWith: UserShareViewObject[];
    sharedWithMe: boolean;
    shareWithOrganizations: OrganizationShareViewObject[];
}
export interface OrganizationShareViewObject {
    companyName: string;
    customerId: number;
    permission: FavoriteListPermission;
}
export interface SearchShareFavoriteViewModel {
    customers?: CustomerViewObject[];
    hasMoreResults: boolean;
    users?: UserViewObject[];
}
export interface UserShareViewObject {
    permission: FavoriteListPermission;
    user: UserViewObject;
}
export interface CustomerAddressViewObject {
    address1?: string;
    address2?: string;
    city: string;
    companyName: string;
    contact?: string;
    countryCode: string;
    county?: string;
    email: string;
    phone?: string;
    postalCode?: string;
    region?: string;
    street: string;
    zip?: string;
}
export interface CustomerBrandTilesViewModel {
    brandTiles: CustomerBrandTileViewObject[];
    isServiceAvailable: boolean;
}
export interface CustomerBrandTileViewObject {
    brandId: number;
    brandName: string;
    imageUrl: string;
}
export interface CustomerContactViewObject {
    contact?: string;
    email?: string;
    phone?: string;
}
export interface CustomerPaymentMethodViewObject {
    code?: string;
    description?: string;
    feePct: number | undefined;
    fixedFee: number | undefined;
    isDefault: boolean;
    maxFeeAmount: number | undefined;
    minFeeAmount: number | undefined;
    restrictForCurrency?: string;
    restrictForDepartment?: string;
    terminal?: string;
}
export interface CustomerShippingViewObject {
    isPartDeliveryAllowed: boolean;
    isPartDeliveryDefault: boolean;
    shipmentEmail?: string;
    shipmentMethod?: string;
}
export interface CustomerViewModel {
    customer: CustomerViewObject;
    isServiceAvailable: boolean;
}
export interface CustomerViewObject {
    activeDirectDebit: boolean;
    activePriceFiles: boolean;
    activeXmlEdiIntegrations: boolean;
    addressInformation: CustomerAddressViewObject;
    billToCustomer?: string;
    blocking: CustomerBlockingType;
    contactInformation: CustomerContactViewObject;
    customerNo: string;
    description?: string;
    dpaSignedCustom: Date | undefined;
    dpaSignedOnWeb: Date | undefined;
    id: number;
    invoiceEmail?: string;
    lineReferenceRequired: boolean;
    name?: string;
    orderEmail?: string;
    priceInclVat: boolean;
    referenceNoRequired: boolean;
    shippingDetails: CustomerShippingViewObject;
}
export interface ShipToCountriesViewModel {
    countries: string[];
    isServiceAvailable: boolean;
}
export interface EmployeeViewModel {
    employees: EmployeeViewObject[];
}
export interface EmployeeViewObject {
    directPhone: string;
    displayName: string;
    email: string;
    id: string;
    initials: string;
    mobilePhone?: string;
    photoUrl?: string;
    title: string;
}
export interface AddOrUpdateDeliveryAddressRequest {
    city: string;
    code?: string;
    companyName: string;
    contact: string;
    countryCode: string;
    email: string;
    isDefault: boolean;
    isFavorite: boolean;
    phone: string;
    postalCode: string;
    region?: string;
    street: string;
    street2?: string;
    webId?: string;
}
export interface DeleteDeliveryAddressRequest {
    webId: string;
}
export interface DeliveryPriceViewModel {
    isServiceAvailable: boolean;
    price: MoneyViewObject;
    shippingMethodCode?: string;
    vat: MoneyViewObject;
}
export interface ShowNextDeliveryViewModel {
    secondsUntilNextDelivery: number | undefined;
}
export interface CompanySearchViewModel {
    companySearchResults: CompanySearchViewObject[];
}
export interface CompanySearchViewObject {
    city: string;
    companyName: string;
    country: string;
    countryCode: string;
    cvr: string;
    duns: string;
    phone: string;
    postalCode: string;
    street: string;
    website: string;
}
export interface DepartmentRequest {
    department?: string;
}
export interface AltaPayRequest {
    transactionId: string;
    xml: string;
}
export interface CheckoutViewModel {
    deliveryAddresses: DeliveryAddressViewObject[];
    deliveryMethods: DeliveryMethodViewObject[];
    isErpServiceAvailable: boolean;
    isLineReferenceRequired: boolean;
    isPartDeliveryAllowed: boolean;
    isPartDeliveryDefault: boolean;
    isReferenceRequired: boolean;
    maxNumberOfShipments: number;
    paymentMethods: PaymentMethodViewObject[];
    shipToCountries: string[];
}
export interface DeliveryAddressViewModel {
    deliveryAddresses: DeliveryAddressViewObject[];
    isServiceAvailable: boolean;
}
export interface DeliveryAddressViewObject {
    city: string;
    code?: string;
    companyName: string;
    contact: string;
    countryCode: string;
    email: string;
    isDefault: boolean;
    isEditable: boolean;
    isFavorite: boolean;
    phone: string;
    postalCode: string;
    region?: string;
    street: string;
    street2?: string;
    webId?: string;
}
export interface DeliveryMethodViewObject {
    description: string;
    shipmentEmailMandatory: boolean;
    shipmentPhoneMandatory: boolean;
    shippingMethodCode: string;
}
export interface OrderViewModel {
    paymentUrl?: string;
    uniqueIdentifier: string;
}
export interface PaymentMethodViewObject {
    description?: string;
    isCreditCard: boolean;
    paymentMethodCode: string;
    terminal?: string;
    type: PaymentMethodType;
}
export interface ReceiptViewModel {
    additionalEmail?: string;
    comment?: string;
    customerNumber: string;
    deliveryAddress: AddressViewObject;
    deliveryMethod?: DeliveryMethodViewObject;
    deliveryPrice: DeliveryPriceWithLinesViewModel;
    email: string;
    invoiceAddress: AddressViewObject;
    lineItems: ReceiptLineItemViewObject[];
    name: string;
    orderContact?: string;
    paymentMethod?: PaymentMethodViewObject;
    quotes: ReceiptQuoteViewObject[];
    reference?: string;
    subTotal: MoneyViewObject;
    total: MoneyViewObject;
    totalExVat: MoneyViewObject;
    totalVat: MoneyViewObject;
}
export interface SendReceiptRequest {
    email: string;
    orderId: string;
}
export interface SubmitOrderRequest {
    creditCardName?: string;
    terminal?: string;
}
export interface ReceiptLineItemViewObject {
    bidDescription?: string;
    bidNumber?: string;
    brand?: string;
    displayName?: string;
    duties?: DutyViewObject[];
    euEccn?: string;
    image?: ImageViewObject;
    itemId: string;
    mpnNumber?: string;
    quantity: number;
    quoteId?: string;
    reference?: string;
    total: MoneyViewObject;
    unitPrice: MoneyViewObject;
    url?: string;
    usEccn?: string;
}
export interface ReceiptQuoteViewObject {
    deliveryAddress: AddressViewObject;
    invoiceAddress: AddressViewObject;
    lineItems: ReceiptLineItemViewObject[];
    partDelivery: boolean;
    quoteId: string;
    shippingAgent: string;
    shipToCode: string;
    total: MoneyViewObject;
    totalWithVat: MoneyViewObject;
    yourReference: string;
}
export interface BasketQuoteViewObject {
    deliveryAddress: AddressViewObject;
    invoiceAddress: AddressViewObject;
    lineItems: LineItemViewObject[];
    partDelivery: boolean;
    quoteId: string;
    shippingAgent: string;
    shipToCode: string;
    total: MoneyViewObject;
    totalWithVat: MoneyViewObject;
    yourReference: string;
}
export interface BasketViewModel {
    additionalEmailForDeliveryUpdates?: string;
    comment?: string;
    currencyCode?: string;
    deliveryAddress?: AddressViewObject;
    deliveryPrice?: DeliveryPriceWithLinesViewModel;
    informationLabels: InformationBoxViewObject[];
    invoiceAddress?: AddressViewObject;
    isPartDelivery: boolean | undefined;
    lineItems: LineItemViewObject[];
    orderContact?: string;
    paymentMethodCode?: string;
    postponeShipmentDate: Date | undefined;
    quotes: BasketQuoteViewObject[];
    reference?: string;
    shippingMethodCode?: string;
    shippingMethodLocked: boolean;
    subTotal: MoneyViewObject;
    total: MoneyViewObject;
    totalExVat: MoneyViewObject;
    totalVat: MoneyViewObject;
}
export interface DeliveryPriceLineViewObject {
    description: string;
    price: MoneyViewObject;
}
export interface DeliveryPriceWithLinesViewModel {
    deliveryPriceLines: DeliveryPriceLineViewObject[];
    price: MoneyViewObject;
    vat: MoneyViewObject;
}
export interface InformationBoxViewObject {
    descriptionLabel: string;
    textToMergeInLabel?: string;
    titleLabel: string;
}
export interface LineItemViewObject {
    bidDescription?: string;
    bidId: number | undefined;
    bidMaxQty: number | undefined;
    bidNumber?: string;
    brand?: string;
    categoryId?: string;
    conditionCode: ProductConditionCode | undefined;
    displayName: string;
    duties?: DutyViewObject[];
    gppg?: string;
    image?: ImageViewObject;
    itemId: string;
    lineItemId: string;
    mpnNumber?: string;
    originalTotal?: MoneyViewObject;
    originalUnitPrice?: MoneyViewObject;
    priceChanged: boolean;
    quantity: number;
    quoteId?: string;
    reference?: string;
    supplementaryProducts: ProductTileViewObject[];
    total?: MoneyViewObject;
    unitPrice?: MoneyViewObject;
    url?: string;
    wid: string;
}
export interface CategorySeoInformationViewModel {
    seoDescription: string;
    seoTitle: string;
}
export interface CableGuideCableTypesViewModel {
    cableTypes: CableGuideCableTypeViewObject[];
}
export interface CableGuideCableTypeViewObject {
    icon?: ImageViewObject;
    id: string;
    name: string;
    productCount: number;
}
export interface BusinessEntityInformationViewModel {
    businessEntityInformationViewObjects: BusinessEntityInformationViewObject[];
    isServiceAvailable: boolean;
}
export interface BusinessEntityInformationViewObject {
    businessEntityName: string;
    companyEmail: string;
    companyOpeningHours: string;
    companyPhoneNo: string;
    id: number;
}
export interface CountryOptionsPreselectViewModel {
    options: CountryPickerOptionPreselectViewObject[];
}
export interface CountryPickerOptionPreselectViewObject {
    allowed: boolean;
    allowEmptyCrnAndVat: boolean;
    countryCode: string;
    erpBusinessEntityId: number;
    preSelected: boolean;
    showCrn: boolean;
    showVat: boolean;
}
export interface OfficesViewModel {
    offices: OfficeViewObject[];
}
export interface OfficeViewObject {
    companyName: string;
    email: string;
    erpBusinessEntityId: number;
    openingHours: string;
    phoneNumber: string;
}
export interface AddProductsToBasketRequest {
    items: AddProductsToBasketItemDto[];
}
export interface AddQuoteToBasketRequest {
    quoteId: string;
    shippingMethodLocked: boolean;
}
export interface AddRackConfigurationToBasketRequest {
    configurationId: string;
    reference?: string;
}
export interface AddToBasketRequest extends BasketRequest {
    lineItems: LineItem[];
}
export interface BasketRequest {
    updateDeliveryPrice: boolean;
    updateLineItemPrices: boolean;
}
export interface RemoveFromBasketRequest extends BasketRequest {
    lineItemIds: number[];
}
export interface UpdateBasketRequest extends BasketRequest {
    additionalEmailForDeliveryUpdates?: string;
    comment?: string;
    deliveryAddress?: AddressRequestObject;
    deliveryPhoneNumber?: string;
    isPartDelivery: boolean | undefined;
    orderContact?: string;
    paymentMethodCode?: string;
    postponeShipmentDate: Date | undefined;
    reference?: string;
    shippingMethodCode?: string;
    shouldPostponeShipment: boolean | undefined;
}
export interface UpdateLineItemRequest extends BasketRequest {
    wid: string;
}
export interface AddProductsToBasketItemDto {
    itemId: string;
    quantity: number;
    reference?: string;
}
export interface LineItem {
    bidId: number | undefined | null;
    isDuplicated: boolean;
    lineItemId?: string;
    quantity: number;
    quoteId?: string;
    reference?: string;
    wid: string;
}
export interface ChangePasswordRequest {
    confirmNewPassword: string;
    newPassword: string;
    oldPassword: string;
}
export interface LoginRequest {
    email: string;
    password: string;
    resendTwoFactorCode: boolean;
    siteId: number;
    twoFactorCode: number | undefined;
}
export interface LoginViewModel {
    contactKey?: string;
    countryCode: string;
    culture: string;
    customerKey?: string;
    enableTwoFactorFlow: boolean;
    erpBusinessEntityId: number;
    languageCode: string;
    marketId: number;
    siteId: number;
    token: string;
    url: string;
}
export interface RequestResetPasswordRequest {
    email: string;
}
export interface ResetPasswordRequest {
    password: string;
    token: string;
}
export interface ValidateResetPasswordTokenViewModel {
    isTokenValid: boolean;
}
export interface NextDeliveryCountriesViewModel {
    countries: NextDeliveryCountryViewObject[];
}
export interface NextDeliveryCountryViewObject {
    countryCode: string;
    id: number;
}
export interface NextDeliveryHolidayConfigurationViewModel {
    configurations: NextDeliveryHolidayConfigurationViewObject[];
}
export interface NextDeliveryHolidayConfigurationViewObject {
    countryId: number;
    date: Date;
    id: number;
    noCountdown: boolean;
    noDelivery: boolean;
}
export interface NextDeliveryHolidayDisplayRuleViewModel {
    nextDeliveryHolidayDisplayRules: NextDeliveryHolidayDisplayRuleViewObject[];
}
export interface NextDeliveryHolidayDisplayRuleViewObject {
    countryId: number;
    date: Date;
    fromHour: number;
    fromMinute: number;
    id: number;
    toHour: number;
    toMinute: number;
}
export interface NextDeliveryHolidayTimeViewModel {
    nextDeliveries: NextDeliveryHolidayTimeViewObject[];
}
export interface NextDeliveryHolidayTimeViewObject {
    countryId: number;
    date: Date;
    id: number;
    nextDeliveryHour: number;
    nextDeliveryHourMinute: number;
}
export interface NextDeliverySettingsViewModel {
    active: boolean;
    countryId: number;
    timeZoneId: string;
}
export interface NextDeliveryWeekDisplayRuleViewModel {
    nextDeliveryDisplayRules: NextDeliveryWeekDisplayRuleViewObject[];
}
export interface NextDeliveryWeekDisplayRuleViewObject {
    countryId: number;
    fromHour: number;
    fromMinute: number;
    id: number;
    toHour: number;
    toMinute: number;
    weekday: number;
}
export interface NextDeliveryWeekTimeViewModel {
    nextDeliveries: NextDeliveryWeekTimeViewObject[];
}
export interface NextDeliveryWeekTimeViewObject {
    countryId: number;
    id: number;
    nextDeliveryHour: number;
    nextDeliveryHourMinute: number;
    weekDay: DayOfWeek;
}
export interface TimeZoneViewModel {
    timeZones: TimeZoneViewObject[];
}
export interface TimeZoneViewObject {
    displayName: string;
    id: string;
}
export interface AddOrUpdateNextDeliveryHolidayConfigurationRequest {
    countryId: number;
    date: Date;
    id: number;
    noCountdown: boolean;
    noDelivery: boolean;
}
export interface AddOrUpdateNextDeliveryHolidayDisplayRuleRequest {
    countryId: number;
    date: Date;
    fromHour: number;
    fromMinute: number;
    id: number | undefined;
    toHour: number;
    toMinute: number;
}
export interface AddOrUpdateNextDeliveryHolidayTimeRequest {
    countryId: number;
    date: Date;
    id: number | undefined;
    nextDeliveryHour: number;
    nextDeliveryHourMinute: number;
}
export interface AddOrUpdateNextDeliverySettingsRequest {
    countryId: number;
    nextDeliveryIsActive: boolean;
    timeZoneId: string;
}
export interface AddOrUpdateNextDeliveryWeekDisplayRuleRequest {
    countryId: number;
    fromHour: number;
    fromMinute: number;
    id: number | undefined;
    toHour: number;
    toMinute: number;
    weekday: DayOfWeek;
}
export interface AddOrUpdateNextDeliveryWeekTimeRequest {
    countryId: number;
    id: number | undefined;
    nextDeliveryHour: number;
    nextDeliveryHourMinute: number;
    weekDay: DayOfWeek;
}
export interface DeleteNextDeliveryHolidayDisplayRuleRequest {
    countryId: number;
    id: number;
}
export interface DeleteNextDeliveryHolidayTimeRequest {
    countryId: number;
    id: number;
}
export interface DeleteNextDeliveryWeekDisplayRuleRequest {
    countryId: number;
    id: number;
}
export interface DeleteNextDeliveryWeekTimeRequest {
    countryId: number;
    id: number;
}
export enum NotificationType {
    Profile = 0,
    OrderHistory = 1,
    ProductBacklog = 2,
    Quotes = 3,
    BlanketOrders = 4,
    Bids = 5,
    RmaReturns = 6,
    Ledger = 7,
    Favourites = 8,
    Settings = 9,
    AddressBook = 10,
    Administration = 11,
    Impersonation = 12
}
export enum OrderStatusViewObject {
    Received = 0,
    PickingInProgress = 1,
    PartlyDispatched = 2,
    Dispatched = 3
}
export interface AccountLedgerViewModel extends PaginatedViewModel {
    documentTypes: LedgerDocumentType[];
    isServiceAvailable: boolean;
    orderInformation: AccountLedgerViewObject[];
}
export interface AccountLedgerViewObject {
    amount: MoneyViewObject;
    documentNo: string;
    documentType: LedgerDocumentType;
    dueDate: string;
    id: number;
    isDue: boolean;
    openingBalance: MoneyViewObject;
    orderId: string;
    postingDate: string;
    referenceNo: string;
    total: MoneyViewObject;
    totalEntries: MoneyViewObject;
}
export interface BidsQuotesRmaViewModel {
    numberOfActiveBids: number;
    numberOfActiveQuotes: number;
    numberOfRmaCases: number;
}
export interface MyAccountCompletionViewModel {
    completionInPercent: number;
}
export interface NotificationsViewModel {
    notificationItems: NotificationType[];
}
export interface OrderHeaderViewModel {
    isServiceAvailable: boolean;
    orderInformation: OrderHeaderViewObject[];
    resultOverflow: boolean;
}
export interface OrderHeaderViewObject {
    amount: MoneyViewObject;
    amountWithVat: MoneyViewObject;
    blocked: boolean;
    expirationDate?: string;
    expireSoon: boolean;
    itemIds: string[];
    orderDate: string;
    orderId: string;
    ourContact: string;
    quantity: number;
    referenceNo: string;
    remainingQuantity: number | undefined;
    shipmentDate: string;
    shippedQuantity: number | undefined;
    shippingAgent: string;
    shipToAddress: string;
    shipToContact: string;
    status: OrderStatusViewObject;
    total: MoneyViewObject;
    trackingLinks: TrackingLinkViewObject[];
    yourReference: string;
}
export interface OrderHistoryDetailLineViewObject {
    brand?: string;
    categoryId?: string;
    comment?: string;
    conditionCode: ProductConditionCode | undefined;
    displayName?: string;
    image?: ImageViewObject;
    invoiceNo: string;
    itemId: string;
    lineDiscountAmount: number | undefined;
    lineDiscountPercent: number | undefined;
    mpnNumber?: string;
    nextShipment: string[];
    quantity: number;
    referenceNo: string;
    remainingQuantity: number;
    shippedQuantity: number;
    total: MoneyViewObject;
    totalWithVat: MoneyViewObject;
    unitPrice: MoneyViewObject;
    url?: string;
}
export interface OrderHistoryDetailShippedLineItemViewObject {
    description: string;
    isReceipt: boolean;
    itemId: string;
    mpnNumber?: string;
    quantity: number;
    serialNumbers?: string;
    shipmentDate: string;
    shipmentNo: string;
    trackingUrl: string;
}
export interface OrderHistoryDetailShippedLineViewObject {
    isReceipt: boolean;
    lineItems: OrderHistoryDetailShippedLineItemViewObject[];
    serialNumbers?: string;
    shipmentDate: string;
    shipmentNo: string;
    trackingUrl: string;
}
export interface OrderHistoryDetailViewModel {
    isServiceAvailable: boolean;
    orderHistoryDetail?: OrderHistoryDetailViewObject;
}
export interface OrderHistoryDetailViewObject {
    amountExclVat: MoneyViewObject;
    amountInclVat: MoneyViewObject;
    amountSubTotal: MoneyViewObject;
    amountVat: MoneyViewObject;
    authAmount: string;
    blocked: boolean;
    createdBy?: EmployeeViewObject;
    currency: string;
    deliveryAddress: AddressViewObject;
    deliveryPrice: DeliveryPriceWithLinesViewModel;
    expirationDate?: string;
    expireSoon: boolean;
    invoiceAddress: AddressViewObject;
    invoiced: boolean;
    invoiceNumbers: string[];
    maxShipments: number;
    orderDate: string;
    orderEmail?: string;
    orderId: string;
    orderLines: OrderHistoryDetailLineViewObject[];
    partDelivery: boolean;
    referenceNo: string;
    shippedLines: OrderHistoryDetailShippedLineViewObject[];
    shippingAgent: string;
    shippingAgentName: string;
    shipToCode: string;
    transactionNo: string;
    webPaymentCode: string;
    yourReference: string;
}
export interface OrderLinesViewModel {
    isServiceAvailable: boolean;
    orderInformation: OrderLineViewObject[];
    resultOverflow: boolean;
}
export interface OrderLineViewObject {
    amount: MoneyViewObject;
    amountWithVat: MoneyViewObject;
    bidNo: string;
    blocked: boolean;
    brand?: string;
    categoryId?: string;
    conditionCode: ProductConditionCode | undefined;
    displayName?: string;
    image?: ImageViewObject;
    itemId: string;
    lineDiscountAmount: number;
    lineDiscountPercent: number;
    lineReference: string;
    manufacturerPartNo?: string;
    mpnNumber?: string;
    nextShipment: string[];
    orderDate: string;
    orderedQty: number;
    orderId: string;
    overflow: boolean;
    price: MoneyViewObject;
    referenceNo: string;
    remQty: number;
    rmaNo: string;
    shipmentDate: string;
    shippedQty: number;
    shippingAgent: string;
    shipToAddress: string;
    shipToContact: string;
    trackingLink?: string;
    trackingNo?: string;
    url?: string;
    yourReference: string;
}
export interface PermissionsViewModel {
    showCustomProductGuides: boolean;
    showDirectDebitBlock: boolean;
    showNextDeliveryAdministration: boolean;
    showRackConfigurationMenuItem: boolean;
}
export interface SendMailViewModel {
    mailViewObject: SendMailViewObject[];
}
export interface SendMailViewObject {
    documentNumber: string;
    success: boolean;
}
export interface TrackingLinkViewObject {
    itemId: string;
    link: string;
    shipmentDate: string;
    shippedQty: number;
    shippingAgent: string;
    trackingNo: string;
}
export enum LedgerColumn {
    DueDate = 0,
    PostingDate = 1,
    OrderId = 2,
    ReferenceNo = 3,
    DocumentNo = 4
}
export interface BidRequest {
    bids?: string[];
    brands?: string[];
    lastFacet?: string;
    webCategories?: string[];
}
export interface LedgerDownloadRequest {
    documentTypes?: LedgerDocumentType[];
    endDate: Date | undefined;
    searchText?: string;
    showOpenOnly: boolean;
    sortColumn?: LedgerColumn;
    sortOrder?: ListSortDirection;
    startDate: Date | undefined;
}
export interface LedgerRequest {
    documentTypes?: LedgerDocumentType[];
    endDate: Date | undefined;
    page?: number;
    pageSize?: number;
    searchText?: string;
    showOpenOnly: boolean;
    sortColumn?: LedgerColumn | undefined;
    sortOrder?: ListSortDirection | undefined;
    startDate: Date | undefined;
}
export interface OrderHeaderRequest {
    dateEnd: Date | undefined;
    dateStart: Date | undefined;
    searchText: string;
    type: AccountHeaderOrderType;
}
export interface OrderLinesRequest {
    dateEnd: Date | undefined;
    dateStart: Date | undefined;
    searchText: string;
    type: AccountLineType;
}
export interface OrderRequest {
    orderId: string;
    orderType: AccountDetailOrderType;
}
export interface SendMailRequest {
    documentNumber: string;
    documentType: EmailDocumentType;
    recipient: string;
}
export interface RequestAccessResponseViewModel {
    prospectNumber: string;
}
export interface EnrichAccessRequest {
    businessLines: string[];
    countryCode: string;
    newsLetterAccepted: boolean;
    prospectNumber: string;
}
export interface RequestAccessRequest {
    address: string;
    campaignSource?: string;
    city: string;
    companyRegistrationNumber: string;
    contact: string;
    contactKey: string;
    countryCode: string;
    cvrNumber: string;
    duns?: string;
    eetContactPerson?: string;
    email: string;
    emailCompany?: string;
    emailInvoice?: string;
    emailStatement?: string;
    homepage: string;
    name: string;
    origin?: string;
    phone: string;
    termsAccepted: boolean;
    zip: string;
}
export enum ContentWidth {
    FullWidth = 0,
    TwoThird = 1,
    OneThird = 2
}
export enum HorizontalAlignment {
    Left = 0,
    Right = 1
}
export enum RequestBreakpoint {
    Mobile = 0,
    Tablet = 1,
    Desktop = 2
}
export enum SubheaderFontSize {
    Normal = 0,
    Large = 1
}
export interface AdContentBlockViewModel {
    adUnitId?: string;
    backgroundColor?: string;
    backgroundImages: AdImage[];
    callToAction?: Link;
    callToActionColor?: string;
    caption?: string;
    captionColor?: string;
    clickPixelUrl: string;
    contentAligmentValue: HorizontalAlignment | undefined;
    contentBackgroundColor?: string;
    contentWidthValue: ContentWidth | undefined;
    creativeId?: string;
    header?: string;
    headerColor?: string;
    imageLeft?: AdImage;
    imageRight?: AdImage;
    layoutId?: string;
    lineItemId?: string;
    makeContentBackgroundTransparent: boolean | undefined;
    marginColor?: string;
    menuColor?: string;
    overlayVimeoCta?: string;
    overlayVimeoVideoId?: string;
    renderedPixelUrl: string;
    shim: boolean | undefined;
    shimColor?: string;
    subheader?: string;
    subheaderColor?: string;
    subheaderFontSizeValue: SubheaderFontSize | undefined;
    takeoverTheme?: string;
    topColor?: string;
    viewPixelUrl: string;
    vimeoVideoId?: string;
    visiblePixelUrl: string;
}
export interface AdImage {
    height: number;
    url: string;
    width: number;
}
export interface BrandLogoViewModel extends AdnuntiusResponse {
    brandUrl?: string;
    image: AdImage;
    name?: string;
}
export interface CategoryItemViewModel extends AdnuntiusResponse {
    categoryDisplayName?: string;
    icon: AdImage;
}
export interface Link {
    name?: string;
    target?: string;
    url?: string;
}
export interface PartnerLogoViewModel extends AdnuntiusResponse {
    image: AdImage;
    name?: string;
}
export interface AdnuntiusResponse {
    clickPixelUrl: string;
    renderedPixelUrl: string;
    url?: string;
    viewPixelUrl: string;
    visiblePixelUrl: string;
}
export interface AdnuntiusPixelForwardRequest {
    deliveryUrls: string[];
}
export interface BaseGetPersonalizedContent {
    brands?: string[];
    contactHash?: string;
    contactKey: string;
    countryCode: string;
    currentUrl: string;
    customerKey?: string;
    isLoggedIn: boolean;
    languageCode: string;
    searchTerm?: string;
}
export interface GetProductDetailsPageBannerRequest extends BaseGetPersonalizedContent {
    brand: string;
    categoryIds: string[];
    itemId: string;
}
export enum Feature {
    PrerenderRedirect = 1,
    AddressSuggest = 2,
    GoogleTranslatePdp = 3,
    LimitProductSheetToStaff = 4,
    RmaV2 = 5,
    ProductListingPageV2 = 6,
    ProductListingPageSearchWithinResult = 7,
    BecomeACustomerDynamics = 8
}
export enum SiteName {
    Eet = 0,
    Oprema = 1,
    EetRetail = 2
}
export enum AssetType {
    PrimaryImage = 0,
    Image = 1,
    Document = 2,
    Video = 3,
    VideoLink = 4,
    BrandLogo = 5,
    CategoryFallbackImage = 6,
    CableGuideIcon = 7,
    Unknown = 8,
    ProductSheet = 9
}
export enum ProductConditionCode {
    C1 = 1,
    C2 = 2,
    C3 = 3,
    Exchange = 4,
    Refurb = 5,
    SpecialOr = 6
}
export enum ListSortDirection {
    Ascending = 0,
    Descending = 1
}
export enum RequestServiceType {
    OrderIntegration = 1,
    PriceFiles = 2,
    DirectDebit = 3
}
export enum StockLocationTypeViewObject {
    Local = 0,
    Remote = 1,
    InComing = 2,
    Leadtime = 3,
    Unknown = 4
}
export enum ExportFormat {
    Xml = 0,
    Csv = 1
}
export enum ImageRequirement {
    DontShow = 0,
    ShowMandatory = 1,
    ShowOptional = 2
}
export enum RmaHeaderOrderStatus {
    AwaitingCustomer = 0,
    Requested = 1,
    Pending = 2,
    Approved = 3,
    Inprogress = 4,
    AwaitingCredit = 5,
    Closed = 6,
    Rejected = 7
}
export enum RmaReasonType {
    Defect = 0,
    Return = 1,
    Delivery = 2,
    Unknown = 10
}
export enum RmaType {
    Exchange = 0,
    Credit = 1,
    Return = 2
}
export enum ProductRecommendationStockFilterType {
    None = 0,
    Local = 1,
    Remote = 2,
    Both = 3
}
export enum PopularityTypes {
    MostPurchased = 5,
    MostViewed = 10
}
export enum RackType {
    WallMounted10 = 1,
    FreeStanding19 = 2,
    WallMounted19 = 3,
    Outdoor = 4
}
export enum FavoriteListPermission {
    View = 0,
    Edit = 1
}
export enum CustomerBlockingType {
    None = 0,
    CanLoginButCannotPlaceOrder = 1,
    Complete = 2
}
export enum PaymentMethodType {
    Account = 0,
    CreditCard = 2,
    Prepaid = 3
}
export enum AccountDetailOrderType {
    Quotes = 0,
    Order = 1,
    BlanketOrders = 2
}
export enum AccountHeaderOrderType {
    Quotes = 0,
    CurrentOrders = 1,
    HistoricOrders = 3,
    BlanketOrders = 5
}
export enum AccountLineType {
    CurrentOrders = 1,
    HistoricOrders = 2
}
export enum EmailDocumentType {
    Invoice = 2,
    CreditMemo = 3,
    FinanceChargeMemo = 4,
    SalesShipmentHeader = 6
}
export enum LedgerDocumentType {
    Blank = 0,
    Payment = 1,
    Invoice = 2,
    CreditMemo = 3,
    RenteNota = 4,
    Reminder = 5
}

